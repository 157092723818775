import { getAuthHeader, appendFormData, paramBuilder } from "../services/utils";
import { updateUser } from "../store/user";
import { rankCodeList } from "../data/generalData";
import store from "../store";
const axios = require("axios");

const updateProfileInfo = (res) => {
    store.dispatch(
        updateUser({
            title: res.data.title,
            uuid: res.data.uuid,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            fullName: res.data.full_name,
            image: res.data.profile_image,
            email: res.data.email,
            mobilePrefix: res.data.mobile_prefix,
            mobile: res.data.mobile_number,
            totalPoint: res.data.total_point,
            expiredDate: res.data.xeersoft_expiry_date ? res.data.xeersoft_expiry_date : null,
            oneSignalHashUuid: res.data.one_signal_info?.hashed_uuid,
            cwallet: res.data.mlm_info ? Number(res.data.mlm_info.cwallet).toFixed(2) : 0,
            pwallet: res.data.mlm_info ? Number(res.data.mlm_info.pwallet).toFixed(2) : 0,
            rank: res.data.mlm_info
                ? getRankText(Math.max(res.data.mlm_info.rank, res.data.mlm_info.crank))
                : "No Rank",
            ref_username: res.data.mlm_info ? res.data.mlm_info?.ref_username : null,
            referral_code: res.data.username,
            inheritor: res.data.inheritor,
            kyc_info: res.data.kyc_info,

        })
    );
};

const getRankText = (type) => {
    const rankList = rankCodeList;
    let target = rankList.find((rank) => rank.code == type);
    let text = target?.name ? target.name : "";
    return text;
};


export async function getProfile() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                 updateProfileInfo(data);
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function updateProfile(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                updateProfileInfo(data);
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function changePassword(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/change-password?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function changeSecurityPassword(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/change-security-password?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getAddress(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function addAddress(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function updateAddress(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function deleteAddress(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address?_method=DELETE", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function resendVerificationLink(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/email/resend", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getRewardPoints(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/reward-point" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getMyNotification(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/notification" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getMyDashboard() {
    let url = process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/dashboard-data";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getBank() {
    let url = process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/bank-info";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function updateBank(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/update-bank?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function getWallet() {
    let url = process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/wallet-info";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getTransferWallet() {
    let url = process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/transfer-wallet-info";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function requestWithdrawal(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/withdraw", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function walletTransfer(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_MLM_API_URL + process.env.REACT_APP_MLM_API_PREFIX + "/wallet-transfer", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function forgetSecurityPassword(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/forgot-security-password";
    data.portal_type = process.env.REACT_APP_PORTAL_TYPE;
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function resetSecurityPassword(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/reset-security-password";
    data.portal_type = process.env.REACT_APP_PORTAL_TYPE;
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function deleteAccount() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/delete-account?_method=DELETE";

    return new Promise((resolve, reject) => {
        axios
            .post(url, null, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function updateInheritor(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/inheritor?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

// KYC getkyc

export async function getKYC() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/kyc";
    return new Promise((resolve, reject) => {
        axios
        .get(url, {
            headers: getAuthHeader(),
            withCredentials: true,
            timeout: 180000,
        })
        .then((response) => {
            const { data } = response;
            resolve(data);
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.data) {
                    reject({ http_code: error.response.status, data: error.response.data });
                } else {
                    reject(error.response.data);
                }
            }
        });
    });
} 

export async function updateKYC(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/kyc", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}

export async function resubmitKYC(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/kyc?_method=PUT", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}
